.App-Section {
    margin-bottom: 24px;
}

.App-title {
    color: inherit;
    margin-bottom: 4px;
}

.App-title + .App-selectable-box {
    margin-top: 14px;
}

.App-subtitle {
    color: var(--color-gray);
    margin-bottom: 18px;
}

.App-selectable-box {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    border-radius: 16px;
    border: 1px solid var(--color-border);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
    padding: 16px;
    margin-bottom: 10px;
}

.App-selectable-box.vip:after {
    content: "";
    width: 249.581px;
    height: 463px;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: linear-gradient(90deg, rgba(0, 105, 255, 0.00) 0%, rgba(0, 105, 255, 0.10) 50%, rgba(0, 105, 255, 0.10) 55%, rgba(0, 105, 255, 0.00) 100%);
    animation: slide 5s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
}

@keyframes slide {
    0% {
        left: calc(-100% + 60px);
    }
    25% {
        left: calc(100% + 60px);
    }
    100% {
        left: calc(100% + 60px);
    }
}

.App-selectable-box-title {
    display: inline-flex;
    align-items: center;
    position: relative;
}

.App-selectable-box-title span {
    margin-left: 8px;
    font-size: 18px;
    position: absolute;
    right: 0;
    padding-left: 8px;
    transform: translateX(100%);
}

.App-selectable-box-subtitle {
    flex-shrink: 0;
    padding-left: 10px;

    span {
        color: var(--color-gray);
    }
}

.App-radio-button-outside {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid rgba(11, 16, 28, 0.08);
    margin-right: 18px;
    flex-shrink: 0;
}

.App-selectable-box.active .App-radio-button-outside {
    border-color: var(--color-theme);
}

.App-radio-button-active {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: var(--color-theme);
    flex-shrink: 0;
}

.spacer {
    flex-grow: 1;
}

.dark {
    .App-title {
        font-weight: 900;
    }
    .App-selectable-box {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.00) 158.62%);
        box-shadow: none;
    }

    .App-radio-button-outside {
        border-color: rgba(255, 255, 255, 0.24);
    }

    .App-selectable-box.vip:after {
        background: linear-gradient(270deg, rgba(26, 30, 42, 0.00) 0%, rgba(34, 38, 48, 0.45) 10%, #2E323C 20.5%, #434750 30.5%, rgba(91, 93, 100, 0.90) 42%, rgba(100, 102, 109, 0.90) 54.5%, rgba(91, 93, 100, 0.90) 64.5%, #434750 77%, #2E323C 86%, rgba(26, 30, 42, 0.00) 100%);
    }
}

@media (min-width: 900px) {
    .App-title {
        font-size: 18px;
    }

    .App-title + .App-selectable-box {
        margin-top: 24px;
    }

    .App-subtitle {
        margin-bottom: 24px;
    }

    .App-selectable-box-title {
        font-size: 18px;
    }

    .App-selectable-box-title span {
        font-size: 20px;
    }

    .App-selectable-box-subtitle {
        font-size: 18px;
    }
}