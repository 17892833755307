.Button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-theme);
    border-radius: 16px;
    cursor: pointer;
    flex-grow: 1;
    height: 58px;
    margin-bottom: 14px;
    color: #FFFFFF;
    margin-top: 48px;
}

.Button.disabled {
    color: var(--color-gray);
    background: #FFF;
    pointer-events: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
}

.Button:hover {
    opacity: 0.95;
}

.Button-title {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    flex-shrink: 0;
}

.dark .Button.disabled {
    background: rgba(255, 255, 255, 0.08);
}

@media (min-width: 900px) {
    .Button-title {
        font-size: 16px;
    }

    .Button {
        height: 60px;
    }
}
