@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500;600;700;900&display=swap');
@import './variables.scss';
@import './reset';

body {
  --color-background: #{$white-2};
  --color-foreground: #{$black-3};
  --color-gray: #{$gray-5};
  --color-border: #{$gray};
  --color-theme: #{$blue};

  position: relative;
  margin: 0;
  color: var(--color-foreground);
  background: var(--color-background);
  font-family: $font-fam-main;
  font-size: 15px;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 259px;
    border-radius: 728px;
    background: rgba(255, 255, 255, 0.48);
    filter: blur(256px);
    z-index: 3;
    transform: translateY(-100%);
  }
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
}

body.dark {
  --color-background: #{$black-2};
  --color-foreground: #{$white};
  --color-gray: #{$gray-2};
  --color-border: #{$gray-3};
}

body:has(dialog[open]) {
  /*
        This blocks page scroll when modal is open
    */
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

dialog {
  padding: 0;
  border: none;
}

button {
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

*,
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-focus-ring-color: rgba(0, 0, 0, 0);
  outline: none;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}

p {
  margin: 0;
}

.container {
  padding: 0 16px;
  margin: 0 auto;
  width: 100%;
  max-width: 824px;
}

.hidden-desktop {
  display: none;
}

strong {
  font-family: inherit;
}

.title {
  font-weight: 600;
  color: inherit;
  margin: 0;
  letter-spacing: -0.022em;

  &--sm {
    font-size: clamp(1.125rem, -0.2421rem + 2.8481vw, 2.25rem);
  }

  &--md {
    font-size: clamp(1.5rem, 0.5886rem + 1.8987vw, 2.25rem);
  }

  &--lg {
    font-size: clamp(1.75rem, -0.9842rem + 5.6962vw, 4rem);
  }

  &--xl {
    text-align: center;
    font-size: clamp(2.25rem, 0.1234rem + 4.4304vw, 4rem);
  }

  .dark & {
    font-weight: 900;
  }
}

.section {
  padding: 48px 0;

  .title--xl {
    margin-bottom: 24px;
  }
}

@media (max-width: 900px) {
  body {
    font-size: 16px;
  }

  .section {
    padding: 96px 0 128px;

    .title--xl {
      margin-bottom: 64px;
    }
  }

  .title {
    margin: 0;

    &--md {
      letter-spacing: -0.72px;
    }

    &--xl {
      letter-spacing: -1.28px;
    }
  }
}
