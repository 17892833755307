@import "../../assets/style/global";

.App {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 $padding-mob;
}

.Navigator-nav {
    display: flex;
    margin-bottom: 24px;
    padding: $padding-mob 0;
}

.App-header {
    font-weight: 600;
    color: inherit;
    margin: 0 0 65px;
    letter-spacing: -0.022em;
    font-size: 36px;

    .dark & {
        font-weight: 700;
    }
}

.App-header span:first-of-type {
    color: $blue;
}

.App-input-user-id-container {
    position: relative;
}

.App-input-user-id {
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: inherit;
    border: 1px solid var(--color-border);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
    padding: 16px 115px 16px 16px;
    margin-bottom: 10px;
    width: 100%;
    color: inherit;

    .dark & {
        box-shadow: none;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.00) 158.62%);
    }
}

.App-account-validating-state {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    align-self: center;
    border-radius: 32px;
    padding: 9px 14px;
    font-size: 12px;
    height: 70%;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    margin: auto;
}

.App-account-validating-state.validating {
    border: 1px solid var(--color-border);
    border-radius: 8px;
}

.App-account-validating-state.correct {
    color: #19CB4B;
    background: rgba(23, 223, 79, 0.12);
}

.App-account-validating-state.wrong {
    color: #FF6332;
    background: rgba(255, 112, 51, 0.12);
}

.App-input-invalid-label {
    font-size: 12px;
    color: #9C4C3A;
}

.App-wrong-user-id-error {
    font-size: 13px;
    color: #FF6332;
    text-align: center;
    display: inline-block;
}

.Navigator-a {
    display: none;
    margin-right: 24px;
    color: var(--color-gray);
}

.Navigator-logo {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.3px;
    text-decoration: none;
    padding: 6px 12px;
    border-radius: 200px;
    background: $blue;
    color: $white;
    margin: 0 auto;

    img {
        margin-right: 10px;
        filter: $to-white;
    }

    .dark & {
        background: $white;
        color: $black-2;

        img {
            filter: $to-blue;
        }
    }
}

a.active {
    color: var(--color-foreground);
    pointer-events: none;
    text-decoration: none;
}

.App-link-blue {
    color: $blue;
    text-decoration: underline;
}

@media (min-width: 900px) {
    .Navigator-a {
        display: block;
    }

    .Navigator-nav {
        margin-bottom: 72px;
    }

    .Navigator-logo {
        margin-left: unset;
    }

    .App-header {
        font-size: 64px;
        margin-bottom: 64px;
        max-width: 79%;
    }

    .App-input-user-id {
        border-radius: 12px;
        padding: 16px 20px;
    }
}
