.modalContainer {
  border-radius: 24px;
  overflow: hidden;
}

.modalContent {
  display: block;

  padding: 32px;
}

.modalTitle {
  font-size: 16px;
  text-align: center;
}

@media (min-width: 900px) {
  .modalContent {
    min-width: 400px;
  }
}